import React from "react";

// Helper function for dynamic class names
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

// Statistic component to display each stat item
const Statistic = ({ name, value, change, changeType }) => {
  const valueClass =
    "w-full flex-none text-3xl font-semibold leading-9 tracking-tight text-gray-900";
  const nameClass = "text-sm font-medium text-gray-500";
  const changeClass = classNames(
    "text-xs font-semibold",
    changeType === "positive" ? "text-green-600" : "text-red-600"
  );

  return (
    <div className="flex flex-col justify-between p-6 bg-white shadow sm:rounded-lg">
      <dt className={nameClass}>{name}</dt>
      <dd className={valueClass}>{value}</dd>
      <dd className={changeClass}>{change}</dd>
    </div>
  );
};

// Main component to render the stats
export default function Stats({stats}) {
  return (
    <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <Statistic key={stat.name} {...stat} />
      ))}
    </dl>
  );
}
