// Skeleton
import React from 'react';

export default function Skeleton({ type, count }) {
    // Dark theme and light theme, dark:bg-gray-800 bg-gray-200

    return (
      <>
            {[...Array(count)].map((_, index) => (
            <div className='animate-pulse' key={index}>
                <SkeletonItem key={index} type={type} />
            </div>
            ))}
            </>
        );
    
}


function SkeletonItem(type) {
    const colors = "bg-white";

    if (type === "company") {
      return (
        <div className={`p-4 rounded-xl shadow-md ${colors}`}>
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-full bg-gray-300"></div>
            <div>
              <div className="w-32 h-4 bg-gray-300"></div>
              <div className="w-16 h-4 bg-gray-300"></div>
            </div>
          </div>
        </div>
      );
    } else if (type === "squad") {
      return (
        <div className={`p-4 rounded-xl shadow-md ${colors}`}>
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-full bg-gray-300"></div>
            <div>
              <div className="w-32 h-4 bg-gray-300"></div>
              <div className="w-16 h-4 bg-gray-300"></div>
            </div>
          </div>
        </div>
      );
    } else if (type === "questionnaire") {
        return (
            <div className={`p-4 rounded-xl shadow-md ${colors}`}>
            <div className="flex items-center gap-4">
                <div className="w-16 h-16 rounded-full bg-gray-300"></div>
                <div>
                <div className="w-32 h-4 bg-gray-300"></div>
                <div className="w-16 h-4 bg-gray-300"></div>
                </div>
            </div>
            </div>
        );
      } else if (type === "question") {
        return (
          // Custom question skeleton, height 96px
          <div className={`p-4 rounded-xl shadow-md ${colors} h-64`}>
            <div className="flex items-center gap-4">
              <div className="w-16 h-16 rounded-full bg-gray-300"></div>
              <div>
                <div className="w-32 h-4 bg-gray-300"></div>
                <div className="w-16 h-4 bg-gray-300"></div>
              </div>
            </div>
          </div>


        );
    } else {
      return (
        <div className={`p-4 rounded-xl shadow-md ${colors}`}>
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-full bg-gray-300"></div>
            <div>
              <div className="w-32 h-4 bg-gray-300"></div>
              <div className="w-16 h-4 bg-gray-300"></div>
            </div>
          </div>
        </div>
      );
    }
}