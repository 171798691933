import { useEffect, useState } from "react";
import SquadsComponent, { SquadComponent } from "../components/Squad";
import { getSquads } from "../utils/api";
import Skeleton from "../components/Skeleton";
import { Link } from "react-router-dom";

function Squads() {
  const [squads, setSquads] = useState();

  useEffect(() => {
    getSquads().then((data) => {
      setSquads(data.squads.squads);
    });
  }, []);
  return (
    <div>
      <div>
        {/* Button to create new vragenlijst */}
        <div className="flex justify-left my-5">
          <Link to="/squad/new" className="text-center">
            <div className="bg-primary-700 hover:bg-primary-900 text-white font-bold py-2 px-4 rounded">
              Create new squad
            </div>
          </Link>
        </div>
      </div>
      {squads ? (
        <SquadsComponent squads={squads} setSquads={setSquads} />
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <Skeleton type={"squad"} count={15} />
        </div>
      )}
    </div>
  );
}

export default Squads;
