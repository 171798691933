// HTTP HELPER
// Uses axios, has a bearer token, and a base URL
import axios from 'axios';
import toast from 'react-hot-toast';

const url = 'https://sqapi.suggestied.com/api';
const dev = "https://localhost:7185/api";

const using = url;

const http = axios.create({
    baseURL: using,
    headers: {
        'Content-Type': 'application/json',
    },
});

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.headers = {
            Authorization: `Bearer ${token}`,
        };

        return config;

    }
    return config;
}
);



export default http;