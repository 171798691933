import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { useNavigate, useParams } from "react-router-dom";

import QuestionListEditor from "../../../components/questionnaire/QuestionListEditor";
import { createHealthCheck, createVersion, getQuestionnaireById } from "../../../utils/api";
import Wizard, { Step } from "../../../components/Wizard";

export default function CreateQuestionnaire() {
  const [questionnaire, setQuestionnaire] = useState({
    title: "",
    description: "",
    managerId: 1,
    questions: [],
  });

  // questionnare id
  const [id, setId] = useState(null);
  // version id
  const [versionId, setVersionId] = useState(null);

  const questions = questionnaire.questions;


  // Function to set title, and one for description and for questions
  const setTitle = (title) => {
    setQuestionnaire({ ...questionnaire, title });
  };

  const setDescription = (description) => {
    setQuestionnaire({ ...questionnaire, description });
  };

  const setQuestions = (questions) => {
    setQuestionnaire({ ...questionnaire, questions });
  };

  

const [versionNumber, setVersionNumber] = useState(1);
const [notes, setNotes] = useState("First version");

const navigate = useNavigate();

  // On Submit function
  const onSubmit = async (step) => {
    // log step
    console.log("Step: ", step);
   switch (step) {
      case 0:
         if (id === null) {
          createHealthCheck(questionnaire).then((res) => {
          console.log(res);
          setId(res);
          setStep(1);
        } );
        }

        // next step if id is set
        if (id !== null) {
          setStep(1);
        }

        break;
      case 1:
        // prepare request
        const version = {
          versionNumber: versionNumber,
          healthCheckId: id,
          notes: notes,
          creationDate: new Date().toISOString(),
          isActive: false,
        };

        if (versionId === null) {
          createVersion(version).then((res) => {
            console.log(res);
            setVersionId(res);
            setStep(2);
          });
        } else {
          setStep(2);
        }

        
        break;
      case 2:
        // Redirect to /questionnaire/:id
        navigate(`/questionnaire/${id}`);
        break;
      default:
        break;
    }
  };



  // set step
  const [step, setStep] = useState(0);

  return (
    <form className="space-y-8">
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Create Questionnaire
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {/* create a questionnaire */}
            Here you can create a questionnaire, atleast 1 question is required.
          </p>
        </div>

        <Wizard onSubmit={onSubmit} step={step} setStep={setStep}>
          <Step
            title="Questionnaire Details"
            description="Create a questionnaire"
            isDisabled={false}
          >
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={questionnaire.title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="title"
                    className="block w-full shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="description"
                    id="description"
                    value={questionnaire.description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete="description"
                    className="block w-full shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </Step>
          <Step
            title="Create questionnare version"
            description="Create a version of the questionnaire"
            isDisabled={id === null}
          >
            <CreateVersionForm
              setNotes={setNotes}
              setVersionNumber={setVersionNumber}
              notes={notes}
              versionNumber={versionNumber}
            questionnaireId={id} />
          </Step>
          <Step
            title="Create questions"
            description="Add questions to the questionnaire"
            isDisabled={versionId === null}
          >
            <QuestionListEditor
              questions={questions}
              setQuestions={setQuestions}
              id={id}
              versionId={versionId}
            />
          </Step>
        </Wizard>
      </div>
    </form>
  );
}

// Create questionnare version
const CreateVersionForm = ({
  questionnaireId,
  versionNumber,
  setVersionNumber,
  notes,
  setNotes,
}) => {
  return (
    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div className="sm:col-span-3">
        <label
          htmlFor="versionNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Version Number
        </label>
        <div className="mt-1">
          <input
            type="number"
            id="versionNumber"
            name="versionNumber"
            value={versionNumber}
            onChange={(e) => setVersionNumber(e.target.value)}
            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter version number"
          />
        </div>
      </div>
      <div className="sm:col-span-3">
        <label
          htmlFor="notes"
          className="block text-sm font-medium text-gray-700"
        >
          Notes
        </label>
        <div className="mt-1">
          <textarea
            id="notes"
            name="notes"
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter notes"
          />
        </div>
      </div>
    </div>
  );
};