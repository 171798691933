import React, { useState } from "react";

export const Step = ({ children, isDisabled }) => children;

export default function Wizard({ children, onSubmit, step, setStep }) {
  const steps = React.Children.toArray(children);
  const enabledSteps = steps.filter((step) => !step.props.isDisabled);
  const currentStep = steps[step];

  // function to get next or previous step, if current step is disabled
  const getStep = (direction) => {
    let nextStep = step;
    while (direction === "next" ? nextStep < steps.length - 1 : nextStep > 0) {
      nextStep = direction === "next" ? nextStep + 1 : nextStep - 1;
    }

    return nextStep;
  };

  const next = () => {
    let nextStep = getStep("next");

    // disabled? check steps[nextStep].props.isDisabled
    if (steps[nextStep].props.isDisabled) {
      return;
    }



    setStep(nextStep);
  };

  const prev = () => {
    let prevStep = getStep("prev");
    setStep(prevStep);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    onSubmit(step);



  };


  

  return (
    <form className="space-y-8">
      <StepNavigation steps={steps} currentStepIndex={step} setStep={setStep} />
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {currentStep.props.title}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {currentStep.props.description}
          </p>
        </div>

        {currentStep}

        <div className="pt-5">
          <div className="flex justify-between">
            {step > 0 && (
              <button
                type="button"
                onClick={prev}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                Previous
              </button>
            )}
            <button
              type="button"
              onClick={handleSubmit}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              {step === steps.length - 1 ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const StepNavigation = ({ steps, currentStepIndex, setStep }) => {
  return (
    <nav
      aria-label="Progress"
      className="bg-white shadow sm:rounded-lg p-4 pt-0"
    >
      <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step, index) => (
          <li key={step.props.title} className="md:flex-1">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (step.props.isDisabled) {
                  return;
                }

                setStep(index);

              }}
              className={`group flex flex-col w-full ${
                index === currentStepIndex
                  ? "border-l-4 border-primary-600"
                  : index < currentStepIndex
                  ? "border-l-4 border-primary-600 hover:border-primary-800"
                  : "border-l-4 border-gray-200 hover:border-gray-300"
              } py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4
              ${
                step.props.isDisabled
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer"
              }
              `}
              aria-current={index === currentStepIndex ? "step" : undefined}
            >
              <span
                className={`text-sm font-medium ${
                  index <= currentStepIndex
                    ? "text-yellow-900 group-hover:text-yellow-800"
                    : "text-gray-500 group-hover:text-gray-700"
                }`}
              >
                Step {index + 1}
              </span>
              <span className="text-sm font-medium">{step.props.title}</span>
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );
};
