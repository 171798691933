// Questionnare versions
// URL: /questionnaire-versions/:id
import { Link, useParams } from "react-router-dom";
import { useEffect, useState, version } from "react";
import { cloneHealthCheck, connectHealthCheckToSquad, deleteVersion, generateInvitationLinks, getQuestionnaire, getSquads } from "../utils/api";
import { Dialog } from "@headlessui/react";
import toast from "react-hot-toast";


// Path: src/views/QuestionnaireVersions.js

const QuestionnaireVersions = () => {
    const { id } = useParams();

    const [questionnare, setQuestionnaire] = useState([]);
    // versions
    const [versions, setVersions] = useState(questionnare.versions);

    // is create version dialog open
    const [isCreateVersionDialogOpen, setIsCreateVersionDialogOpen] = useState(false);


    // dialog
    const [isConnectHealthCheckToSquadDialogOpen, setIsConnectHealthCheckToSquadDialogOpen] = useState(false);

    // is send dialog open
    const [isSendDialogOpen, setIsSendDialogOpen] = useState(false);
    // version id
    const [versionId, setVersionId] = useState(null);

    const [squads, setSquads] = useState([]);

    useEffect(() => {
        getQuestionnaire(id).then((versions) => {
            setQuestionnaire(versions);
            setVersions(versions.versions);
            setSquads(versions.squads);
            console.log(versions);
        });
    }

    , []);



    return (
      <div>
        {isConnectHealthCheckToSquadDialogOpen && (
          <ConnectHealthCheckToSquadDialog
            isOpen={isConnectHealthCheckToSquadDialogOpen}
            onClose={() => {
              setIsConnectHealthCheckToSquadDialogOpen(false);
            }}
            squads={squads}
            setSquads={setSquads}
            healthCheckId={id}
          />
        )}

        {isCreateVersionDialogOpen && (
          <CreateVersionDialog
            healthCheckId={id}
            isOpen={isCreateVersionDialogOpen}
            onClose={() => {
              setIsCreateVersionDialogOpen(false);
            }}
            versions={questionnare.versions}
            setVersions={setVersions}
          />
        )}
        {isSendDialogOpen && (
          <SendDialog
            healthCheckId={id}
            isOpen={isSendDialogOpen}
            onClose={() => {
              setIsSendDialogOpen(false);
            }}
            versions={versions}
            setVersions={setVersions}
            versionId={versionId}
            squads={questionnare.squads.map((squad) => {
              return {
                id: squad.id,
                name: squad.name,
                createdAt: squad.createdAt,
              };
            })}
          />
        )}
        <div className="flex flex-col items-center overflow-hidden rounded-xl border border-gray-200 bg-white p-4 text-center pt-8 mb-4">
          <h1 className="text-3xl text-black pb-6 font-mono">
            {questionnare.title}
          </h1>

          <p className="text-gray-800 mb-3 flex">{questionnare.description}</p>
          <button
            onClick={() => {
              setIsConnectHealthCheckToSquadDialogOpen(true);
            }}
            className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded"
          >
            Add Squad
          </button>
        </div>

       {
          questionnare.squads && (
             <Squads squads={questionnare.squads} />
          )
       }

        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-semibold text-gray-900">
            Questionnaire Versions
          </h1>

          {/* BUtton for dialog to create new version */}
          <button
            onClick={() => {
              setIsCreateVersionDialogOpen(true);
            }}
            className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded"
          >
            Create New Version
          </button>
        </div>
        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Version Number
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Creation Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Notes
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit or result</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {versions &&
                      versions.map((version) => (
                        <tr key={version.versionId}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {version.versionNumber}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {version.creationDate}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-sm text-gray-900
                                                    text-wrap
                                                    
                                                    "
                            >
                              {version.notes}
                            </div>
                          </td>

                          {version.isActive ? (
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-4">
                              <Link
                                to={`/questionnaire/${id}/${version.versionId}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Results
                              </Link>
                              {/* Re-send */}
                              <button
                                onClick={() => {
                                  // send version
                                  // sendVersion(version.versionId)
                                  //   .then(() => {
                                  //     toast.success("Version sent successfully");
                                  //   })
                                  //   .catch(() => {
                                  //     toast.error("Error sending version");
                                  //   });
                                }}
                                className="text-green-600 hover:text-green-900"
                              >
                                Re-send
                              </button>
                            </td>
                          ) : (
                            <td className="px-6 space-x-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {/* Send */}
                              <button
                                onClick={() => {
                                  setVersionId(version.versionId);
                                  setIsSendDialogOpen(true);
                                }}
                                className="text-green-600 hover:text-green-900"
                              >
                                Send
                              </button>
                              <Link
                                to={`/questionnaire/${id}/edit/${version.versionId}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Edit
                              </Link>
                              {/* And delete */}
                              <button
                                onClick={() => {
                                  deleteVersion(version.versionId).then(() => {
                                    setQuestionnaire((prevVersions) => {
                                      return prevVersions.filter(
                                        (v) => v.versionId !== version.versionId
                                      );
                                    });
                                  });

                                  toast.success("Version deleted successfully");
                                }}
                                className="text-red-600 hover:text-red-900"
                              >
                                Delete
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

}


// Dialog
const CreateVersionDialog = ({
  isOpen,
  onClose,
  versions,
  setVersions,
  healthCheckId,
}) => {
  // version number
  const [versionNumber, setVersionNumber] = useState("");
  // notes
  const [notes, setNotes] = useState("");
  // clone version
  const [cloneVersion, setCloneVersion] = useState("");

  // on submit function
  const onSubmit = () => {
    // cloneHealthCheck = {
    //   healthCheckId,
    //   params,
    // }; 
    // params

    var params = {
      versionId: cloneVersion,
      versionNumber: versionNumber,
      notes: notes,
      creationDate: new Date().toISOString(),
      isActive: false,
    
    };

    cloneHealthCheck(
      healthCheckId,
      cloneVersion,
      params
    ).then((response) => {
      toast.success("Health check cloned successfully");
      // id = response
      setVersions((prevVersions) => {
        return [
          ...prevVersions,
          {
            versionId: response,
            versionNumber: versionNumber,
            notes: notes,
            creationDate: new Date().toISOString(),
            isActive: false,
          },
        ];
      });
      onClose();
    });
  };

  return (
    <Dialog
      as="div"
      className={isOpen ? "fixed inset-0 z-10 overflow-y-auto" : ""}
      open={isOpen}
      onClose={onClose}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <Dialog.Panel className="bg-white p-4 rounded-xl border border-gray-200 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Dialog.Title className={`text-2xl font-semibold text-gray-900`}>
          Create New Version
        </Dialog.Title>
        <Dialog.Description className="text-gray-500 text-sm">
          Create a new version of the questionnaire
        </Dialog.Description>
        {/* Version number */}
        <div>
          <label
            htmlFor="versionNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Version Number
          </label>
          <div className="mt-1">
            <input
              value={versionNumber}
              onChange={(e) => setVersionNumber(e.target.value)}
              type="number"
              id="versionNumber"
              name="versionNumber"
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter version number"
            />
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="notes"
            className="block text-sm font-medium text-gray-700"
          >
            Notes
          </label>
          <div className="mt-1">
            <textarea
              id="notes"
              name="notes"
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter notes"
            />
          </div>
        </div>

        {/* Option select to clone another version */}
        <div className="mt-4">
          <label
            htmlFor="cloneVersion"
            className="block text-sm font-medium text-gray-700"
          >
            Clone Version
          </label>
          <div className="mt-1">
            <select
              value={cloneVersion}
              onChange={(e) => setCloneVersion(e.target.value)}
              id="cloneVersion"
              name="cloneVersion"
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="">Select version to clone</option>
              {versions.map((version) => (
                <option key={version.versionId} value={version.versionId}>
                  {version.versionNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-4">
          <button
            onClick={onSubmit}
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Create
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

// Send dialog
// Meerdere squads kunnen selecten
// InvationLinks aanmaken voor elke member van elke squad die gemaakt is
// Zodat ik het vervolgens via de mail kan sturen
const SendDialog = ({ isOpen, onClose, versions, healthCheckId, versionId = null, squads }) => {

  // LIst of squad ids that i want to send it too
  const [selectedSquads, setSelectedSquads] = useState([]);

  // on submit
  const onSubmit = () => {
    // send version
    // sendVersion(versionId)
    //   .then(() => {
    //     toast.success("Version sent successfully");
    //   })
    //   .catch(() => {
    //     toast.error("Error sending version");
    //   });

    generateInvitationLinks(healthCheckId, versionId, selectedSquads).then(() => {
      toast.success("Invitation links generated successfully");
    });


    onClose();
  };

  return (
    <Dialog
      as="div"
      className={isOpen ? "fixed inset-0 z-10 overflow-y-auto" : ""}
      open={isOpen}
      onClose={onClose}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <Dialog.Panel className="bg-white p-4 rounded-xl border border-gray-200 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2">
        <Dialog.Title className={`text-2xl font-semibold text-gray-900`}>
          Send Version
          {versionId &&
            ` ${versions.find((v) => v.versionId === versionId).versionNumber}`}
        </Dialog.Title>
        <Dialog.Description className="text-gray-500 text-sm">
          Send the version to the participants
        </Dialog.Description>
        <div className="mt-4">
          {squads && (
            <div className="grid grid-cols-2 gap-4 mb-4">
              {squads.map((squad) => {
                return (
                  <div
                    key={squad.id}
                    className="flex items-center justify-between bg-gray-100 p-4 rounded-lg"
                  >
                    <div>
                      <h1 className="text-xl font-semibold">{squad.name}</h1>
                      <p className="text-gray-500">{squad.createdAt}</p>
                    </div>
                    <div>
                      <input type="checkbox"
                      value={
                        selectedSquads.includes(squad.id)
                      }
                        onChange={
                          (e) => {
                            if (e.target.checked) {
                              setSelectedSquads((prevSquads) => {
                                return [...prevSquads, squad.id];
                              });
                            } else {
                              setSelectedSquads((prevSquads) => {
                                return prevSquads.filter((s) => s !== squad.id);
                              });
                            }
                          }
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <button
            onClick={onSubmit}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Send
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

// connectHealthCheckToSquad dialog
const ConnectHealthCheckToSquadDialog = ({ isOpen, onClose, squads, setSquads, healthCheckId }) => {
  const [allSquads, setAllSquads] = useState([]);

  useEffect(() => {
    getSquads().then((data) => {
      setAllSquads(data.squads.squads);
    });
  }, []);

  const onSubmit = (squadId) => {
    connectHealthCheckToSquad(healthCheckId, squadId).then(() => {
      // allSquads, get by id
      const squad = allSquads.find((squad) => squad.id === squadId);
      setSquads([...squads, squad]);
      toast.success("Squad added to company");
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <Dialog.Backdrop className="fixed inset-0 bg-black opacity-30" />
      
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel className="mx-auto w-full max-w-md p-4 bg-white rounded-lg shadow">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Add Squad
          </Dialog.Title>
          <ul className="mt-3 list-none space-y-1">
            {allSquads.map((squad) => (
              <li key={squad.id}>
                <button
                  onClick={() => onSubmit(squad.id)}
                  className="block w-full text-left py-2 px-3 text-sm text-gray-900 hover:bg-gray-100 rounded"
                >
                  {squad.name}
                </button>
              </li>
            ))}
          </ul>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

// Squads in questionnare
const Squads = ({ squads }) => {
  return (
    <div className="flex flex-col items-center overflow-hidden rounded-xl border border-gray-200 bg-white p-4 text-center pt-8 mb-4">
      <h1 className="text-3xl text-black pb-6 font-mono">Squads</h1>
      <div className="flex flex-wrap justify-center">
        {squads.map((squad) => (
          <div
            key={squad.id}
            className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg m-2"
          >
            <h1 className="text-xl font-semibold">{squad.name}</h1>
            <p className="text-gray-500">{squad.createdAt}</p>
          </div>
        ))}
      </div>
    </div>
  );
}



export default QuestionnaireVersions;