import React, { useState, useEffect } from "react";
import { getHealthCheckOverview } from "../utils/api";

export default function Results({ healthcheckId, versionId, squadIds }) {
  const [questionnaires, setQuestionnaires] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const questionnaires = await getHealthCheckOverview(
        healthcheckId,
        versionId,
        squadIds
      );
      setQuestionnaires(questionnaires);
    };
    fetchData();
  }, []);

  const getColorClassName = (color) => {
    switch (color.toLowerCase()) {
      case "green":
        return "bg-green-500";
      case "yellow":
        return "bg-yellow-500";
      case "red":
        return "bg-red-500";
      default:
        return "bg-gray-400";
    }
  };

  return (
    <div className="p-5">
      {questionnaires.map((questionnaire) => (
        <div
          key={questionnaire.id}
          className="mb-10 border border-gray-200 rounded-lg shadow-sm"
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">
              {questionnaire.title}
            </h2>
          </div>
          <div>
            {questionnaire.questions.map((question) => (
              <div key={question.id} className="border-t p-4">
                <h2 className="text-lg font-semibold mb-2">{question.title}</h2>
                <h3 className="font-medium mb-2">{question.description}</h3>
                <div className="flex items-center mt-2 space-x-4">
                  {question.answers.map((answer, index) => (
                    <div key={index} className="flex items-center">
                      <div
                        className={`w-6 h-6 rounded-full ${getColorClassName(
                          answer.color
                        )} flex justify-center items-center`}
                      >
                        <span className="text-white text-sm">
                          {answer.count}
                        </span>
                      </div>
                      <span className="ml-2 text-sm font-semibold">
                        {answer.color}
                      </span>
                    </div>
                  ))}
                </div>
                {question.comments.length > 0 && (
                  <div className="mt-4">
                    <h4 className="text-sm font-semibold mb-1">Comments:</h4>
                    {question.comments.map(
                      (comment, index) => (
                        console.log("comment", comment),
                        (
                          <p key={index} className="text-sm mt-1 italic">
                            "{comment}"
                          </p>
                        )
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
