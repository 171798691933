import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { useParams } from "react-router-dom";

import QuestionListEditor from "../../../components/questionnaire/QuestionListEditor";
import { getQuestionnaireById, getQuestionnaireVersion } from "../../../utils/api";

export default function EditQuestionnaire() {
  const { id, versionId } = useParams();

  const [questionnaire, setQuestionnaire] = useState([]);
  const [questions, setQuestions] = useState(questionnaire.questions);

  useEffect(() => {
    // var fetchQuestionnaire = async () => {
    //   const questionnaire = await getQuestionnaireById(id, versionId);
    //   setQuestionnaire(questionnaire);
    //   setQuestions(questionnaire.questions);
    // };
    // fetchQuestionnaire();
    getQuestionnaireVersion(versionId).then((questionnaire) => {
      setQuestionnaire(questionnaire);
      setQuestions(questionnaire.questions);
    }
    );
  }, [id, versionId]);

  return (
    <form className="space-y-8">
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edit Questionnaire
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Here you can update the questionnaire's questions. Add new
            questions, edit existing ones, or remove them.
          </p>
        </div>

        <QuestionListEditor questions={questions} setQuestions={setQuestions} id={id} versionId={versionId} />
      </div>
    </form>
  );
}
