// Home.js
import React from 'react';


import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import ActionPanel from '../components/ActionPanel';
import Stats from '../components/Stats';

const actions = [

  // Squads
    {
        title: "Squads",
        description: "Manage your squads and members, and send out notifications to your members.",
        href: "/squads",
        icon: UsersIcon,
        iconForeground: "text-indigo-700",
        iconBackground: "bg-indigo-50",
    },
    // Companies
    {
        title: "Companies",
        description: "Manage your companies and the squads that are associated with them.",
        href: "/companies",
        icon: BanknotesIcon,
        iconForeground: "text-green-700",
        iconBackground: "bg-green-50",
    },
    // Questionaires
    {
        title: "Questionaires",
        description: "Create and manage questionaires to send to your members.",
        href: "/questionnaires",
        icon: AcademicCapIcon,
        iconForeground: "text-yellow-700",
        iconBackground: "bg-yellow-50",
    },
    // Results
    {
        title: "Results",
        description: "View and manage the results of your questionaires.",
        href: "/results",
        icon: CheckBadgeIcon,
        iconForeground: "text-blue-700",
        iconBackground: "bg-blue-50",
    },

];

const stats = [
  {
    name: "Completed Health Checks",
    value: "82",
    change: "+15%",
    changeType: "positive",
  },
  {
    name: "Average Response Rate",
    value: "76%",
    change: "+2%",
    changeType: "positive",
  },
  // amount of answers this week
    {
        name: "Answers This Week",
        value: "1,200",
        change: "+10%",
        changeType: "positive",
    },
    // amount of answers this month
    {
        name: "Answers This Month",
        value: "5,200",
        change: "+5%",
        changeType: "positive",
    },
];

function Home() {
    return (
      <div className="flex flex-col justify-center items-center bg-gray-200">
        <div className="w-full mb-4">
          <Stats stats={stats} />
        </div>
        <div className="w-full">

        <ActionPanel actions={actions} />
        </div>
      </div>
    );
    }

export default Home;