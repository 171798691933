import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { getSquads, deleteSquad, disconnectSquadFromCompany } from "../utils/api";
import { company } from "faker/lib/locales/az";

// Example squad status styles
const squadStatuses = {
  Active: "text-green-700 bg-green-50 ring-green-600/20",
  Inactive: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Disbanded: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SquadsComponent({ squads, setSquads, companyId }) {
  const squadsData = squads || [];
  const setSquadsData = setSquads || (() => { });

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
    >
      {squadsData &&
        squadsData.map((squad) => (
          <SquadComponent squad={squad} setSquads={setSquadsData} companyId={companyId} />
        ))}
    </ul>
  );
}

// Squad component with parameters
export function SquadComponent({ squad, setSquads, companyId = null }) {
  // Isactive random
  // const isActive = Math.random() > 0.5;
  const isActive = true;
  return (
    <div className="relative">
      <li
        key={squad.id}
        className={`overflow-hidden h-full rounded-xl border border-gray-200 bg-white ${isActive
            ? "ring-1 ring-green-500/20"
            : "ring-1 ring-gray-500/10 opacity-60"
          }`}
      >
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <Link className="flex items-center gap-x-4" to={`/squad/${squad.id}`}>
            {squad.company && (
              <img
                src={squad.company.logo || ""}
                alt=""
                className="h-12 w-12 flex-none rounded-lg bg-white object-contain ring-1 ring-gray-900/10"
              />
            )}
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {squad.name || "No Name"}
              </p>
              <p className="text-sm text-gray-500 truncate">
                {squad.company && squad.company.name}
              </p>
            </div>
          </Link>

          <Menu as="div" className=" ml-auto">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-50 right-0 z-10 mt-0.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={`squad/${squad.id}`}
                      className={classNames(
                        active ? "bg-gray-50" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      View Details
                    </a>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={`squad/${squad.id}/edit`}
                      className={classNames(
                        active ? "bg-gray-50" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Edit Squad
                    </a>
                  )}
                </Menu.Item>

                {
                  companyId && (
                    <div>
                      <button
                        className="block px-4 py-2 text-sm text-yellow-700 hover:bg-yellow-50 w-full text-left"
                        onClick={() => {
                          disconnectSquadFromCompany(squad.id, companyId)
                            .then(() => {
                              toast.success("Squad successfully disconnected from company");
                              // filter the squad and remove it and set the new squad
                              // searchforthis
                              setSquads((prevSquads) =>
                                prevSquads.filter((s) => s.id !== squad.id)
                              );
                            })
                            .catch(() => {
                              toast.error("Error disconnecting squad from company");
                            });
                        }}
                      >
                        Disconnect Squad
                      </button>

                    </div>
                  )
                }

                <Menu.Item className=" hover:bg-red-50 w-full text-left">
                  <button
                    className="block px-4 py-2 text-sm text-red-700"
                    onClick={() => {
                      deleteSquad(squad.id)
                        .then(() => {
                          toast.success("Squad deleted successfully");
                          // filter the squad and remove it and set the new squad
                          // searchforthis
                          setSquads((prevSquads) =>
                            prevSquads.filter((s) => s.id !== squad.id)
                          );
                        })
                        .catch(() => {
                          toast.error("Error deleting squad");
                        });
                    }}
                  >
                    Delete
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </li>
    </div>
  );
}
