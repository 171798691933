import Results from "../components/Results";
import Stats from "../components/Stats";

// Stats of one speficic questionnaire (spotify health check)
const stats = [
  {
    name: "Red Answers",
    value: "82",
    changeType: "positive",
    change: "+3 this week",
  },
  // Orange answers
  {
    name: "Orange Answers",
    value: "0",
    change: "0 this week",
    changeType: "positive",
  },
  {
    name: "Green Answers",
    value: "18",
    change: "+2 this week",
    changeType: "positive",
  },
  {
    name: "Total Answers",
    value: "130",
    changeType: "positive",
    change: "+5 this week",
  },
  {
    name: "Total Questions",
    value: "13",
    changeType: "neutral",
    change: "0 this week",
  },
  {
    name: "Total Participants",
    value: "10",
    changeType: "positive",
    change: "+2 this week",
  },
  
];

export default function ResultsPage() {
  return (
    <div className="space-y-4">
      <Stats stats={stats} />
      <div className="bg-white">
        <div className="grid grid-cols-1">
          <Results healthcheckId={1} versionId={1} squadIds={[1, 2]} />
        </div>
      </div>
    </div>
  );
}