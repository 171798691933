// Login page
import React, { useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import logo from '../../logo.svg';

function Login(router) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate('/dashboard');

        console.log(email, password);
    }

    return (
        <div className="h-screen bg-secondary-950 flex flex-col justify-center items-center">
            <img src={logo} alt="logo" className="w-44 h-44 mb-4" />
            <form onSubmit={handleSubmit} className="bg-white p-10 rounded shadow-lg xl:w-1/3 mx-4">
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-secondary-600">Email</label>
                    <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-3 rounded border border-secondary-300" />
                </div>
                <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-secondary-600">Password</label>
                    <input type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-3 rounded border border-secondary-300" />
                </div>
                <button type="submit" className="w-full py-3 mt-4 bg-primary-500 rounded text-secondary-900 font-bold hover:bg-primary-900">Login</button>
            </form>
        </div>
    );
}

export default Login;