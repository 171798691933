// api that returns formatted data like it would be a fetched function with the api (react)

import toast from "react-hot-toast";
import http from "./http";

// promise
const getQuestionnaires = (page = 0, size = 100) => {
  return new Promise((resolve) => {
    http
      .get("/HealthCheck/GetHealthCheckPage", {
        params: {
          pageNum: page,
          size: size,
          includeUsers: false,
          includeSquads: false,
          includeCompany: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching questionnaires");
      });
  });
};

const getCompanies = (page = 0, size = 100) => {
  return new Promise((resolve) => {
    http
      .get("/Company/GetCompanyPage", {
        params: {
          pageNum: page,
          size: size,
          includeUsers: false,
          includeSquads: false,
          includeHealthChecks: false,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching companies");
      });
  });
};

// Get by id
const getCompanyById = (id) => {
  return new Promise((resolve) => {
    http
      .get(`/Company/GetCompany`, {
        params: {
          companyId: id,
          includeUsers: true,
          includeSquads: true,
          includeHealthChecks: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching company");
      });
  });
};

// get squads
const getSquads = (page = 0, size = 100) => {
  return new Promise((resolve) => {
    http
      .get("/Squad/GetSquadPage", {
        params: {
          pageNum: page,
          size: size,
          includeUsers: false,
          includeHealthChecks: false,
          includeCompany: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching squads");
      });
  });
};

// Get Squad by ID, with params
const getSquadById = (id) => {
  return new Promise((resolve) => {
    http
      .get(`/Squad/GetSquad`, {
        params: {
          squadid: id,

          includeUsers: true,
          includeHealthChecks: true,
          includeCompany: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching squad");
      });
  });
};

// Get users
const getUsers = (page = 0, size = 100) => {
  return new Promise((resolve) => {
    http
      .get("/User/GetUserPage", {
        params: {
          pageNum: page,
          size: size,
          includeSquads: false,
          includeHealthChecks: false,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching users");
      });
  });
};

// Create a new user
const createUser = (user) => {
  return new Promise((resolve) => {
    http
      .post("/user/postuser", user)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating user");
      });
  });
};

// Get categories
const getCategories = () => {
  return new Promise((resolve) => {
    http
      .get("/Category/GetCategories")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching categories");
      });
  });
};

// Create category
const createCategory = (category) => {
  return new Promise((resolve) => {
    http
      .post("/Category/PostCategory", category)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating category");
      });
  });
};

// Get questionnare
const getQuestionnaire = (id) => {
  return new Promise((resolve) => {
    http
      .get(`/HealthCheck/GetHealthCheck`, {
        params: {
          healthCheckId: id,
          includeUsers: true,
          includeSquads: true,
          includeCompany: true,
          includeVersions: true,
          includeInvitations: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching questionnaire");
      });
  });
};
// Get by questionnare version
const getQuestionnaireVersion = (versionId) => {
  return new Promise((resolve) => {
    http
      .get(`/HealthCheckVersion/GetVersion`, {
        params: {
          versionId: versionId,
          includeQuestions: true,
          includeAnswers: true,
          includeInvitations: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching questionnaire version");
      });
  });
};

// /Question/postquestion
// "healthCheckId": 0,
//   "priority": 0,
//   "description": "string",
//   "createdAt": "2024-04-20T09:24:02.394Z",
//   "versionId": 0,
//   "title": "string",
//   "categoryId": 0,

const createQuestion = (question) => {
  return new Promise((resolve) => {
    http
      .post("/Question/postquestion", question)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating question");
      });
  });
};

// Update question
// /Question/updatequestion
// "id": 0,
// "healthCheckId": 0,
//   "priority": 0,
//   "description": "string",
//   "createdAt": "2024-04-20T09:24:02.394Z",
//   "versionId": 0,
//   "title": "string",
//   "categoryId": 0,
const updateQuestion = (question) => {
  return new Promise((resolve) => {
    http
      .put("/Question/updatequestion", question)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error updating question");
      });
  });
};

// Delete question
// /Question/deletequestion
// "id": 0,

const deleteQuestion = (id) => {
  return new Promise((resolve) => {
    // Delete question, as a delete request
    http
      .delete("/Question/deletequestion", {
        params: {
          questionId: id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error deleting question");
      });
  });
};

const deleteSquad = (id) => {
  return new Promise((resolve) => {
    // Delete Squad
    http
      .delete("/Squad/DeleteSquad", {
        params: {
          SquadId: id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error deleting Squad");
      });
  });
};

const deleteCompany = (id) => {
  return new Promise((resolve) => {
    // Delete Company
    http
      .delete("/Company/DeleteCompany", {
        params: {
          companyId: id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error deleting Company");
      });
  });
};

// updateCategory
const updateCategory = (category) => {
  return new Promise((resolve) => {
    http
      .put("/Category/updatecategory", category)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error updating category");
      });
  });
};

// Create health check
const createHealthCheck = (healthCheck) => {
  return new Promise((resolve) => {
    http
      .post("/HealthCheck/posthealthcheck", healthCheck)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating health check");
      });
  });
};

// create version
const createVersion = (version) => {
  return new Promise((resolve) => {
    http
      .post("/HealthCheckVersion/postversion", version)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating version");
      });
  });
};

// Clone health check
// /HealthCheck/clonehealthcheck with healthCheckId as post request
// and clone id as get in the params
const cloneHealthCheck = (healthCheckId, cloneId, params = {}) => {
  return new Promise((resolve) => {
    http
      .post(`/HealthCheckversion/cloneversion?cloneId=${cloneId}`, {
        healthCheckId: healthCheckId,
        ...params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error cloning health check");
      });
  });
};

// Delete version
const deleteVersion = (id) => {
  return new Promise((resolve) => {
    http
      .delete("/HealthCheckVersion/deleteversion", {
        params: {
          versionId: id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error deleting version");
      });
  });
};

// generate invitation links based of questionnare id, version id, and list of squad ids
const generateInvitationLinks = (healthCheckId, versionId, squadIds) => {
  return new Promise((resolve) => {
    // seperate squadsId by , so i can map it
    squadIds = squadIds.join(",");

    // map squadIds so its &squadsId=1&squadsId=2
    squadIds = squadIds
      .split(",")
      .map((squadId) => `&squadsId=${squadId}`)
      .join("");

    http
      .post(
        `/InvitationLink/GenerateInvitationLink?questionnaireId=${healthCheckId}&versionId=${versionId}${
          squadIds && squadIds
        }`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error generating invitation links");
      });
  });
};

// connect squad to copamny
// /Squad/ConnectSquadToCompany
// "squadId": 0,
// "companyId": 0,

const connectSquadToCompany = (companyId, squadId) => {
  // convert squadId to int
  const squadIdInt = parseInt(squadId);
  const companyIdInt = parseInt(companyId);
  return new Promise((resolve) => {
    http
      .post(
        `/Squad/ConnectSquadToCompany?squadId=${squadIdInt}&companyId=${companyId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error connecting squad to company");
      });
  });
};

// health to squad
// /HealthCheck/ConnectHealthCheckToSquad
// "healthCheckId": 0,
// "squadId": 0,

const connectHealthCheckToSquad = (healthCheckId, squadId) => {
  return new Promise((resolve) => {
    http
      .post(
        `/HealthCheck/ConnectHealthCheckToSquad?healthCheckId=${healthCheckId}&squadId=${squadId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error connecting health check to squad");
      });
  });
};

// conect user to squad
// /User/ConnectUserToSquad
// "userId": 0,
// "squadId": 0,
const connectUserToSquad = (userId, squadId) => {
  return new Promise((resolve) => {
    http
      .post(`/User/ConnectUserToSquads?userId=${userId}&squadId=${squadId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error connecting user to squad");
      });
  });
};

// disconnect user from squad
// /User/DisconnectUserFromSquad
// "userId": 0,
// "squadId": 0,
const disconnectUserFromSquad = (userId, squadId) => {
  return new Promise((resolve) => {
    http
      .delete(
        `/User/DisconnectUserFromSquads?userId=${userId}&squadId=${squadId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error disconnecting user from squad");
      });
  });
};

// factory/gethealthcheckoverview
// "healthCheckId": 0,
// squadIds[] - array of squad ids
// "versionId": 0,
// get
const getHealthCheckOverview = (healthCheckId, versionId) => {
  return new Promise((resolve) => {
    http
      .get(
        `/Factory/GetHealthCheckOverview?healthCheckId=${healthCheckId}&versionId=${versionId}&mode=1`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching health check overview");
      });
  });
};

// create squad
// /Squad/postSquad

const createSquad = (squad) => {
  return new Promise((resolve) => {
    http
      .post("/Squad/postSquad", squad)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating squad");
      });
  });
};

// factory/HealthCheck/Export
// "healthCheckId": 0,
// "versionId": 0,
// "mode": 0,
const exportHealthCheck = (healthCheckId, versionId) => {
  return new Promise((resolve) => {
    http
      .get(
        `/Factory/HealthCheck/Export?healthCheckId=${healthCheckId}&versionId=${versionId}&mode=0`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error exporting health check");
      });
  });
};

// disconnect squad from company
// /Squad/DisconnectSquadFromCompany
// "squadId": 0,
// "companyId": 0,

const disconnectSquadFromCompany = (squadId, companyId) => {
  return new Promise((resolve) => {
    http
      .delete(
        `/Squad/DisconnectSquadFromCompany?squadId=${squadId}&companyId=${companyId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error disconnecting squad from company");
      });
  });
};


const updateSquad = (body) => {
  return new Promise((resolve) => {
    const uri = "/Squad/UpdateSquad";
    http
      .put(`${uri}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        toast.error("Error creating squad");
      });
  });
};

export {
  getQuestionnaires,
  getCompanies,
  getCompanyById,
  getSquads,
  getSquadById,
  getUsers,
  createUser,
  getCategories,
  createCategory,
  getQuestionnaire,
  getQuestionnaireVersion,
  createVersion,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  updateCategory,
  createHealthCheck,
  cloneHealthCheck,
  deleteVersion,
  generateInvitationLinks,
  connectSquadToCompany,
  connectHealthCheckToSquad,
  connectUserToSquad,
  disconnectUserFromSquad,
  getHealthCheckOverview,
  deleteSquad,
  deleteCompany,
  createSquad,
  exportHealthCheck,
  disconnectSquadFromCompany,
  updateSquad,
};
