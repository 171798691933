// with Id
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SquadsComponent from "../components/Squad";
import { companies } from "../mock/mockData";
import { MapIcon } from "@heroicons/react/24/outline";
import Stats from "../components/Stats";
import { Dialog } from "@headlessui/react";
import { connectSquadToCompany, getCompanyById, getSquads } from "../utils/api";
import toast from "react-hot-toast";

export default function Company() {
  // props.match.params.id
  let { companyId } = useParams();
  // state
  const [company, setCompany] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [squads, setSquads] = useState([]);

  useEffect(() => {
    getCompanyById(companyId).then((data) => {
      setCompany(data);

      // loop through data.squads, and add comany to each squad
      data.squads.forEach((squad) => {
        squad.company = data;
      });
      setSquads(data.squads);
    });
  }, [companyId]);

  return (
    <div>
      <AddSquadDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        squads={squads}
        setSquads={setSquads}
        companyId={companyId}
      />
      <div className="w-full space-y-8">
        {company && (
          <div className="flex flex-row items-center overflow-hidden rounded-xl border border-gray-200 bg-white">
            <img src={company.logo} alt="logo" className="w-44 h-full" />
            <div className="ml-4">
              <h1 className="text-3xl text-black pb-6">{company.name}</h1>
              <h3 className="text-xl text-gray-800 mb-3 flex">
                <MapIcon className="h-6 w-6 mt-1 mr-2" />
                {company.location}
              </h3>
            </div>
          </div>
        )}

        <div className="w-full">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-3xl text-black pb-6">Squads</h1>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-primary-500 text-white rounded px-8 py-2"
            >
              Add Squad
            </button>
          </div>
          {
            company && (
              <SquadsComponent squads={squads} setSquads={setSquads} companyId={company.id}/>
            )
          }
        </div>
      </div>
    </div>
  );
}

// TODO: AddSquadDialog
export function AddSquadDialog({
  isOpen,
  onClose,
  squads,
  setSquads,
  companyId,
}) {
  const [allSquads, setAllSquads] = useState([]);

  useEffect(() => {
    getSquads().then((data) => {
      const sqads = data.squads.squads.filter(
        (squad) => !squads.find((s) => s.id === squad.id)
      );
      // only the ones that dont have company
      setAllSquads(sqads.filter((squad) => !squad.company));
    });
  }, []);

  const onSubmit = (squadId) => {
    connectSquadToCompany(companyId, squadId).then(() => {
      // allSquads, get by id
      const squad = allSquads.find((squad) => squad.id === squadId);
      setSquads([...squads, squad]);
      toast.success("Squad added to company");
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <Dialog.Backdrop className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel className="mx-auto w-full max-w-md p-4 bg-white rounded-lg shadow">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Add Squad
          </Dialog.Title>
          <ul className="mt-3 list-none space-y-1">
            {allSquads.map((squad) => (
              <li key={squad.id}>
                <button
                  onClick={() => onSubmit(squad.id)}
                  className="block w-full text-left py-2 px-3 text-sm text-gray-900 hover:bg-gray-100 rounded"
                >
                  {squad.name}
                </button>
              </li>
            ))}
          </ul>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
