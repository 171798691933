import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import toast from "react-hot-toast";
import { createCategory, createQuestion, deleteQuestion, getCategories, updateCategory, updateQuestion } from "../../utils/api";
import Skeleton from "../Skeleton";

const DeleteConfirmation = ({ isOpen, setIsOpen, confirmDelete }) => {
  return (
    <Dialog
      className="relative z-50"
      as="div"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="bg-white p-8 rounded-lg shadow-lg">
          <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
            Delete question
          </Dialog.Title>
          <Dialog.Description as="div" className="text-sm text-gray-500">
            Are you sure you want to delete this question? This action cannot be
            undone.
          </Dialog.Description>

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => confirmDelete()}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Yes, delete
            </button>
            <button onClick={() => setIsOpen(false)}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md" 
            >Cancel</button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

// Create a new category dialog
const CreateCategoryDialog = ({ isOpen, setIsOpen, setCategories }) => {
  // State for the new category name
  const [newCategoryName, setNewCategoryName] = useState("");

  // onSubmit
  const createCategorySubmit = (e) => {
    e.preventDefault();
    const newCategory = {
      id: Date.now(),
      name: newCategoryName,
    };
    setCategories((prevCategories) => [...prevCategories, newCategory]);
    setNewCategoryName("");
    setIsOpen(false);

    createCategory({
      name: newCategoryName,
    }).then(() => {


    toast.success("Category created");

    }).catch(() => {
      toast.error("Error creating category");
    }
    );
  };

  return (
    <Dialog
      className="relative z-50"
      as="div"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="bg-white p-8 rounded-lg shadow-lg">
          <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
            Create new category
          </Dialog.Title>
          <Dialog.Description as="div" className="text-sm text-gray-500">
            Create a new category for your questions
          </Dialog.Description>

          <div className="mt-4">
            <label
              htmlFor="categoryName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category Name
            </label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter category name"
            />
          </div>

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => setIsOpen(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                createCategorySubmit(e);
                setIsOpen(false);
              }
              }
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Create
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

// Edit category dialog
const EditCategoryDialog = ({ isOpen, setIsOpen, setCategories, categoryId, categories }) => {
  const [newCategoryName, setNewCategoryName] = useState();

  useEffect(() => {
    if (categoryId) {
      const category = categories.find((cat) => cat.id === categoryId);
      setNewCategoryName(category.name);
    }
  }, [categoryId, categories]);

  const updateCategorySubmit = (e) => {
    e.preventDefault();
    const categoryToUpdate = {
      id: categoryId,
      name: newCategoryName,
    };

    // Assuming updateCategory is an API utility to update categories on the server
    updateCategory(categoryToUpdate)
      .then(() => {
        // Update local state only on successful server update
        setCategories((prev) =>
          prev.map((cat) =>
            cat.id === categoryId ? { ...cat, name: newCategoryName } : cat
          )
        );
        toast.success("Category updated");
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error("Failed to update category: " + error.message);
      });
  };


  return (
    <Dialog
      className="relative z-50"
      as="div"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="bg-white p-8 rounded-lg shadow-lg">
          <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
            Edit category
          </Dialog.Title>
          <Dialog.Description as="div" className="text-sm text-gray-500">
            Edit the name of the category
          </Dialog.Description>

          <div className="mt-4">
            <label
              htmlFor="categoryName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category Name
            </label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter category name"
            />
          </div>

          <div className="mt-4 flex justify-end space-x-4">
            <button

              onClick={() => setIsOpen(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button

              onClick={(e) => {
                updateCategorySubmit(e);
                setIsOpen(false);
              }}

              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Update
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};


const QuestionListEditor = ({ questions, setQuestions, id = null, versionId = null }) => {
  // Categories state
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories
    getCategories().then((data) => {
      setCategories(data);
    });
  }, []);


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    setQuestions(items);

    toast.success("Question reordered");
  };

  const addNewQuestion = (e) => {
    e.preventDefault();
    if (!id || !versionId) {
      toast.error("Missing healthCheckId or versionId");
      return;
    }

    const newQuestion = {
      title: "Nieuwe vraag",
      description: "Beschrijving van de vraag",
      categoryId: categories.length > 0 ? categories[0].id : null,
      healthCheckId: id, // Assuming `id` is your `healthCheckId`
      versionId: versionId,
      // It will be passed as  "2024-04-20T10:05:16.241Z",
      createdAt: new Date().toISOString(),
      priority: 0, // You can adjust the priority as needed
    };

    // log the new question
    console.log(newQuestion);

    createQuestion(newQuestion)
      .then((response ) => {
        // if response has a id
        newQuestion.id = response;
        setQuestions([...questions, newQuestion]);
        toast.success("Question added");
      })
      .catch((error) => {
        toast.error("Error adding question: " + error.message);
      });
  };


  const updateQuestionText = (questionId, field, newValue) => {
    if (!id || !versionId) {
      toast.error("Missing healthCheckId or versionId");
      return;
    }

    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        const updatedQuestion = { ...question, [field]: newValue };
        updateQuestion({
          ...updatedQuestion,
          healthCheckId: id, // Assuming `id` is your `healthCheckId`
          versionId: versionId,
        })
          .then(() => {
            console.log("Question updated");
          })
          .catch((error) => {
            toast.error("Error updating question: " + error.message);
          });
        return updatedQuestion;
      }
      return question;
    });

    setQuestions(updatedQuestions);
  };



  const deleteQuestionFromList = (id) => {
    const updatedQuestions = questions.filter((question) => question.id !== id);

    deleteQuestion(id).then(() => {
      toast.success("Question deleted");
    setQuestions(updatedQuestions);
    }).catch((error) => {
      toast.error("Error deleting question: " + error.message);
    });
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);


  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);

  const [editCategoryOpen, setEditCategoryOpen] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);



  // on click of delete, if shift + delete, then delete the question, otherwise setOpen to true
  const handleDelete = (id, e) => {
      e.preventDefault();

    if (e.shiftKey) {
      deleteQuestionFromList(id);
    } else {
                            setDeleteConfirmationOpen(true);
                            setConfirmDeleteId(id);
  }
  };

  

  return (
    <div>
      <DeleteConfirmation
        isOpen={deleteConfirmationOpen}
        setIsOpen={setDeleteConfirmationOpen}
        confirmDelete={() => {
          deleteQuestionFromList(confirmDeleteId);
          setDeleteConfirmationOpen(false);
        }}
      />

      <CreateCategoryDialog
        isOpen={createCategoryOpen}
        setIsOpen={setCreateCategoryOpen}
        setCategories={setCategories}
      />

      <EditCategoryDialog
        isOpen={editCategoryOpen}
        setIsOpen={setEditCategoryOpen}
        setCategories={setCategories}
        categoryId={editCategoryId}
        categories={categories}
      />

      
      { questions
       ? (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableQuestions">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-4"
            >
              {questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={String(question.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="flex flex-col bg-white p-6 rounded-lg shadow-sm border border-gray-200"
                    >
                      {/* Handle for dragging */}
                      <div
                        {...provided.dragHandleProps}
                        className="flex justify-between items-center mb-4"
                      >
                        <div className="flex items-center space-x-2">
                          <PencilIcon className="h-5 w-5 text-gray-400" />
                          <span className="text-gray-500">
                            Question {index + 1}
                          </span>
                        </div>
                        <span className="text-xs text-gray-500">
                          Drag to reorder
                        </span>
                        <button
                        // Tip
                          title="Click to delete, Shift + Click to delete without confirmation"
                          onClick={(e) => handleDelete(question.id, e)}
                          className="p-2 rounded-full hover:bg-red-200 group bg-red-300/10 transition-colors duration-200"
                        >
                          <TrashIcon className="h-8 w-8 text-red-600 group-hover:text-red-800" />
                        </button>
                      </div>
                      {/* Question Title */}
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700 mb-2"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          value={question.title}
                          onChange={(e) =>
                            updateQuestionText(question.id, "title", e.target.value)
                          }
                          className="w-full p-2 border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Enter question title"
                        />
                      </div>
                      {/* Question Text */}
                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 mb-2"
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          value={question.description}
                          onChange={(e) =>
                            updateQuestionText(question.id, "description", e.target.value)
                          }
                          className="w-full p-2 border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Enter question description"
                        />
                      </div>
                      <div className="mt-4 flex">
                        <div className="w-full">
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Category
                          </label>
                          <div className="flex items-center space-x-2">
                            <select className="w-full p-2 border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            onChange={
                              (e) => {

                                updateQuestionText(question.id, "categoryId", e.target.value);
                              }
                            } 
                            
        
                            value={question.categoryId}>
                              {/* Null option */}
                              <option value="0">No category</option>
                              {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            <div className="flex space-x-2 justify-center items-center">
                              {/* Edit icon and create + */}
                              <button
                                onClick={(e) => {
                                  e.preventDefault();

                                  question.categoryId ?
                                  (() => {
                                    setEditCategoryId(question.categoryId);
                                    if (categories.find((cat) => cat.id === question.categoryId)) 
                                    setEditCategoryOpen(true);
                                  })()
                                  : toast.error("Please select a category to edit");
                                  
                                }}
                              >
                                <PencilIcon className="h-6 w-6 text-blue-600 group-hover:text-blue-800" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCreateCategoryOpen(true);
                                }}
                              >
                                <PlusIcon className="h-6 w-6 text-green-600 group-hover:text-green-800" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Checkbox if orange is disabled, for spotify squadhealthcheck */}
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">
                          <input
                            type="checkbox"
                            className="rounded border-gray-300 text-orange-500 shadow-sm focus:ring-orange-500 focus:border-orange-500"
                          />
                          <span className="ml-2">Disable Orange</span>
                        </label>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <button
          onClick={addNewQuestion}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
          Add Question
        </button>
      </DragDropContext>
      ) : (
        <div className="w-full">
          <Skeleton type="question" count={5} />
        </div>
      )}
    </div>
  );
};

// isOpen, setIsOpen, deleteQuestion, questionId

export default QuestionListEditor;
