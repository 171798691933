// Create a squad

import { useEffect, useState } from "react";
import { connectSquadToCompany, createSquad, getCompanies } from "../../../utils/api";
import toast from "react-hot-toast";
import Wizard, { Step } from "../../../components/Wizard";
import { useNavigate } from "react-router-dom";

export default function CreateSquad() {
  const [name, setName] = useState("");
  const [companyId, setCompanyId] = useState(1);

  // companies where a squad can be created
  const [companies, setCompanies] = useState([
    { id: 1, name: "Company A" },
    { id: 2, name: "Company B" },
    { id: 3, name: "Company C" },
  ]);

  useEffect(() => {
    getCompanies()
      .then((res) => {
        // log the response
        console.log(res.companies.companies);
        setCompanies(res.companies.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [step, setStep] = useState(0);

  // state for squadId
  const [squadId, setSquadId] = useState(null);

  const navigate = useNavigate();
  // OnSubmit
  const onSubmit = () => {
    switch (step) {
      case 0:
        createSquad({ name })
          .then((res) => {
            console.log(res);
            setSquadId(res);
            toast.success("Squad created successfully");
          })
          .catch((err) => {
            toast.error(err.message);
          });
        break;
      case 1:
        connectSquadToCompany(companyId, squadId)
          .then((res) => {
            console.log(res);
            toast.success("Squad connected to company successfully");
          })
          .catch((err) => {
            toast.error(err.message);
          });

          navigate("/squads");
        
        break;
      default:
        break;
    }
  };

  // state for step

  return (
    <div>
      <Wizard step={step} setStep={setStep} onSubmit={onSubmit}>
        <Step title="Create a squad" description="Enter the name" isDisabled={squadId !== null}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="mt-8">
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                  {/* Squad Name */}
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="squad-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Squad Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="squad-name"
                        id="squad-name"
                        autoComplete="squad-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md px-3 py-2"
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
        </Step>
        <Step title="Choose a company" description="Select a company" isDisabled={squadId === null}>
          <div  className="grid grid-cols-2 gap-4">
                       {
                          companies.map((companyy) => (
                            <div key={companyy.id} onClick={() => setCompanyId(companyy.id)} className={`p-4 ${
                              companyy.id === companyId ? "bg-primary-100 ring ring-primary-500" : ""
                            } rounded-md cursor-pointer`}>
                              {companyy.name}
                            </div>
                          ))
                       }
                       </div>
                        </Step>
      </Wizard>
    </div>
  );
}
