// Create a squad

import { useState } from "react";
import { updateSquad } from "../../../utils/api";
import { useParams } from "react-router-dom";

export default function EditSquad() {
  // Name
  const [name, setName] = useState("");
  const {squadId} = useParams();

  // OnSubmit
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(name);
    const body = {
      id: squadId,
      name: name,
    };

    updateSquad(body);
  };



  return (
    <div>
      <form onSubmit={onSubmit} className="space-y-8">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Squad
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Update your squad information here. Please ensure that the details
                are accurate before saving.
            </p>
          </div>
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              {/* Squad Name */}
              <div className="sm:col-span-6">
                <label
                  htmlFor="squad-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Squad Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="squad-name"
                    id="squad-name"
                    autoComplete="squad-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md px-3 py-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
