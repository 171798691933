

const companies = [
    {
        id: 1,
        company_name: "Rabobank",
        location: "Utrecht",
        logo: "/demo/rabo.jpeg",
        },
        {
            id: 2,
            company_name: "ASML",
            location: "Veldhoven",
            logo: "/demo/asml.jpeg",
        },
        {
            id: 3,
            company_name: "Nedap",
            location: "Groenlo",
            logo: "/demo/ns.webp",
        },
        {
            id: 4,
            company_name: "Tax",
            location: "Amsterdam",
            logo: "/demo/tax.webp",
        },
        {
            id: 5,
            company_name: "Essent",
            location: "Amsterdam",
            logo: "/demo/essent.webp",
        },

];

const users = [
  {
    id: 1,
    email: "manager@example.com",
    password_hash: "hashed_password1",
    role: "Manager",
    created_at: "2023-01-01T00:00:00Z",
  },
  {
    id: 2,
    email: "developer@example.com",
    password_hash: "hashed_password2",
    role: "Developer",
    created_at: "2023-01-02T00:00:00Z",
  },
    {
        id: 3,
        email: "developer+2@example.com",
        password_hash: "hashed_password3",
        role: "Developer",
        created_at: "2023-01-03T00:00:00Z",
    },
    {
        id: 4,
        email: "test@gmail.com",
        password_hash: "hashed_password4",
        role: "Developer",
        created_at: "2023-01-04T00:00:00Z",
    },
];

const squads = [
  {
    id: 1,
    squad_name: "Alpha Team",
    created_at: "2023-01-03T00:00:00Z",
    company: 1,
  },
  {
    id: 2,
    squad_name: "Beta Squad",
    created_at: "2023-02-01T00:00:00Z",
    company: 2,
  },
    {
        id: 3,
        squad_name: "Gamma Force",
        created_at: "2023-03-01T00:00:00Z",
        company: 3,
    },
    {
        id: 4,
        squad_name: "Delta Team",
        created_at: "2023-04-01T00:00:00Z",
        company: 4,
    },
    {
        id: 5,
        squad_name: "Tax Team",
        created_at: "2023-05-01T00:00:00Z",
        company: 5,
    },

];

const questionnaires = [
  {
    id: 1,
    title: "Monthly Feedback",
    description: "Monthly feedback questionnaire for team improvement.",
    is_active: 1,
    manager_id: 1,
    created_at: "2023-03-01T00:00:00Z",
  },
    {
        id: 2,
        title: "Sprint Retrospective",
        description: "Feedback on the last sprint and suggestions for improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 3,
        title: "Team Satisfaction",
        description: "A survey to gauge team satisfaction and areas of improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-05-01T00:00:00Z",
    },
    {
        id: 4,
        title: "Development Process Feedback",
        description: "Feedback on our current development process and suggestions.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-06-01T00:00:00Z",
    },
    {
        id: 5,
        title: "Monthly Feedback",
        description: "Monthly feedback questionnaire for team improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-07-01T00:00:00Z",
    },
    {
        id: 6,
        title: "Sprint Retrospective",
        description: "Feedback on the last sprint and suggestions for improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-08-01T00:00:00Z",
    },
    {
        id: 7,
        title: "Team Satisfaction",
        description: "A survey to gauge team satisfaction and areas of improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-09-01T00:00:00Z",
    },
    {
        id: 8,
        title: "Development Process Feedback",
        description: "Feedback on our current development process and suggestions.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-10-01T00:00:00Z",
    },
    {
        id: 9,
        title: "Monthly Feedback",
        description: "Monthly feedback questionnaire for team improvement.",
        is_active: 1,
        manager_id: 1,
        created_at: "2023-10-01T00:00:00Z",
    },
];

const questions = [
  {
    id: 1,
    questionnaire_id: 1,
    question_text: "How do you feel about the current project progress?",
    created_at: "2023-03-01T01:00:00Z",
  },
    {
        id: 2,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 3,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 4,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 5,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 6,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 7,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 8,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 9,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
    {
        id: 10,
        questionnaire_id: 1,
        question_text: "How do you feel about the current project progress?",
        created_at: "2023-03-01T01:00:00Z",
    },
];

const answers = [
  {
    id: 1,
    question_id: 1,
    user_id: 2,
    answer_color: "Green",
    comment: "I feel positive about our progress and teamwork.",
    created_at: "2023-03-02T00:00:00Z",
  },
    {
        id: 2,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 3,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 4,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 5,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 6,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 7,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
    {
        id: 8,
        question_id: 1,
        user_id: 2,
        answer_color: "Green",
        comment: "I feel positive about our progress and teamwork.",
        created_at: "2023-03-02T00:00:00Z",
    },
];

const invitationLinks = [
  {
    id: 1,
    questionnaire_id: 1,
    user_id: 2,
    unique_link: "https://example.com/invite/abc123",
    is_used: false,
    expires_at: "2023-04-01T00:00:00Z",
  },
    {
        id: 2,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 3,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 4,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 5,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 6,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 7,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
    {
        id: 8,
        questionnaire_id: 1,
        user_id: 2,
        unique_link: "https://example.com/invite/abc123",
        is_used: false,
        expires_at: "2023-04-01T00:00:00Z",
    },
];

const squadQuestionnaires = [{ id: 1, squad_id: 1, questionnaire_id: 1 }];

const squadMembers = [{ id: 1, user_id: 2, squad_id: 1 }];




export {
    companies,
    users,
    squads,
    questionnaires,
    questions,
    answers,
    invitationLinks,
    squadQuestionnaires,
    squadMembers,
    };
    