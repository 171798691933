import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Results from "../components/Results";
import Stats from "../components/Stats";
import { CloseableCard } from "./ViewQuestionnaire";
import { Combobox, Dialog } from "@headlessui/react";
import toast from "react-hot-toast";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {connectUserToSquad, createUser, disconnectUserFromSquad, getSquadById, getUsers, deleteSquad} from "../utils/api";

// Dialog to add a user, and create a new user (just email), and add it to the squad
const AddUserDialog = ({ isOpen, onClose, users, setMembers, setUsers, members, squadId }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setInputValue("");
      setSelectedUser(null);
      setIsCreating(false);
    }
  }, [isOpen]);

  const filteredUsers = inputValue === ''
    ? users
    : users.filter((user) =>
        user.email.toLowerCase().includes(inputValue.toLowerCase())
      );

  const handleAddUser = async () => {
    if (!selectedUser) {
      // No user selected, check if we need to create a new one
      const userExists = users.some((user) => user.email === inputValue);
      if (!userExists && inputValue) {
        setIsCreating(true);
        createUser({
          email: inputValue,
          role: 0,
          passwordHash: "none",
          // to c# datetime
          createdAt: new Date(Date.now()).toISOString(),
        }).then((
          id
        ) => {
          toast.success("User created");
          connectUserToSquad(id, squadId).then(() => {
            toast.success("User added to squad");
          }
          ).catch(() => {
            toast.error("Error adding user to squad");
          }
          );
        }).catch(() => {
          toast.error("Error creating user");
        });
        // await createUserWithEmail(inputValue); // Implement this function
        toast.success("User created and added to squad");
      } else {
        toast.error("User already exists");
      }
      setIsCreating(false);



      setUsers((users) => [
        ...users,
        { email: inputValue, joinedAt: new Date().toISOString(), id: users.length + 1 },
      ]);



      setMembers((members) => [...members, { email: inputValue }]);
      onClose(); // Close the dialog
    } else {
      // Check if the user is already in the squad, so if its already a member
      const userExists = members.some(
        (member) => member.email === selectedUser.email
      );
      if (userExists) {
        toast.error("User is already a member of the squad");
      } else {
        setMembers((members) => [...members, selectedUser]);
        connectUserToSquad(selectedUser.id, squadId).then(() => {

        toast.success("User added to squad");

        }
        ).catch(() => {
          toast.error("Error adding user to squad");
        }
        );
      }

      onClose(); // Close the dialog
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="rounded-md shadow-lg p-6 bg-white">
          <Dialog.Title className="text-xl font-semibold">
            Add User to Squad
          </Dialog.Title>
          <Dialog.Description className="mt-2">
            Type an email to add an existing user or create a new one.
          </Dialog.Description>
          <div className="mt-4">
            {/* Combobox with the possibility to create a new user */}
            <Combobox as="div" value={selectedUser} onChange={setSelectedUser}>
              <div className="relative mt-1">
                <Combobox.Input
                  className="w-full border border-gray-300 bg-white py-2 pl-3 pr-10 rounded-lg shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  displayValue={(user) => user?.email}
                  onChange={(event) => {
                    setInputValue(event.target.value);
                    setSelectedUser(null); // Allow typing freely for new emails
                  }}
                  placeholder="Type an email"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-lg px-2 focus:outline-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {filteredUsers.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {filteredUsers.map((user) => (
                      <Combobox.Option
                        key={user.id}
                        value={user}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-4 pr-4 ${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                          }`
                        }
                      >
                        {user.email}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </div>
          <div className="mt-4 flex items-center justify-between">
            <button
              type="button"
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              Cancel
            </button>
            <button

              type="button"
              onClick={handleAddUser}
              className="bg-indigo-600 text-white px-4 py-2 rounded-lg"
            >
              {isCreating ? "Creating..." : "Add User"}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const SquadDetails = () => {
  let { squadId } = useParams();
  const [squad, setSquad] = useState(null);
  // questionnares
    const [questionnaires, setQuestionnaires] = useState([]);
  // All users
  const [users, setUsers] = useState([
  ]);

  useEffect(() => {
    getUsers().then((data) => {
      // log
        console.log(data.users.users);
      setUsers(data.users.users);
    }
    );
  }, []);




  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);

  const [members, setMembers] = useState([]);

  const stats = [
    {
      name: "Total Members",
      value: members.length,
    },
    {
      name: "Total Questionnaires",
      value: questionnaires.length,
    },
    {
      name: "Total Results",
      value: 20,
    },
    {
      name: "Total Active Questionnaires",
      value: 2,
    }
  ]
    

  useEffect(() => {
    // getSquadById(squadId).then((squad) => {
    //   setSquad(squad);
    // }
    // );
    getSquadById(squadId).then((squad) => {
        setSquad(squad);
        setMembers(squad.users);
        setQuestionnaires(squad.healthChecks);
    }
    );

    }, [squadId]);

  if (!squad) {
    return (
      <div className="text-center font-bold text-xl mt-5">Squad not found</div>
    );
  }

  return (
    <div>
      <AddUserDialog
        isOpen={isUserDialogOpen}
        onClose={() => setIsUserDialogOpen(false)}
        users={users}
        setUsers={setUsers}
        setMembers={setMembers}
        members={members}
        squadId={squadId}
      />
      <div className="mx-auto p-5">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
          <div className="absolute top-0 right-0 p-4">
            <span className="text-white bg-opacity-50 bg-gray-700 rounded-full m-2 px-4 py-2 text-sm">
              {squad.status}
            </span>
          </div>
          <div className="flex items-center p-4">
            {squad.company && (
              <img
                src={squad.company.logo}
                alt={squad.name}
                className="w-16 h-16 rounded-full"
              />
            )}
            <div className="p-4">
              <h2 className="text-3xl font-bold mb-2">{squad.name}</h2>
              {squad.company && (
                <Link
                  className="text-xl text-primary-900 mb-3"
                  to={`/company/${squad.company.id}`}
                >
                  Company: {squad.company.name}
                </Link>
              )}
            </div>
          </div>
          <div className="p-4 border-t border-gray-300/20 text-gray-700">
            <Stats stats={stats} />
          </div>
        </div>

        {/* Members */}
        <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-5 space-y-1">
          <h3 className="text-2xl font-bold p-4">Members</h3>
          {members.length === 0 && (
            <div className="p-4 text-gray-700">No members found</div>
          )}
          <div className="grid lg:grid-cols-2">
            {members &&
              members.map((member) => {
                return (
                  <div key={member.id} className="p-4">
                    <div className="flex items-center mb-2">
                      <div className="ml-2">
                        <h3 className="text-xl font-bold">{member.email}</h3>
                        <p className="text-gray-700">Developer</p>
                      </div>
                      <div className="ml-auto">
                        <button
                          onClick={() => {
                            setMembers((members) =>
                              members.filter((m) => m.id !== member.id)
                            );
                            disconnectUserFromSquad(member.id, squadId)
                              .then(() => {
                                toast.success("Member removed from squad");
                              })
                              .catch(() => {
                                toast.error("Error removing member from squad");
                              });
                          }}
                          className="text-primary-900"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* Add new member  */}
            <div
              className="p-2 group"
              onClick={() => setIsUserDialogOpen(true)}
            >
              <div className="flex items-center mb-2 bg-gray-50 p-2 rounded-lg cursor-pointer group-hover:bg-gray-100">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center group-hover:bg-gray-300">
                  +
                </div>
                <div className="ml-2">
                  <h3 className="text-xl font-bold">Add new member</h3>
                  <p className="text-gray-700">Add new member to the squad</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-5 space-y-1">
            <h3 className="text-2xl font-bold p-4">Questionnaires</h3>

            <div className="grid lg:grid-cols-2 p-4 gap-4">
              {questionnaires.length === 0 && (
                <div className="text-gray-700">No questionnaires found</div>
              )}

              {questionnaires.map((questionnaire) => (
                <div>
                  {questionnaire && (
                    <Link to={`/questionnaire/${questionnaire.id}`}>
                      <div className="p-4 bg-gray-50 hover:bg-gray-200 rounded-lg cursor-pointer text-center py-8">
                        <h1 className="text-2xl font-bold text-primary-900">
                          {questionnaire.title}
                        </h1>
                        <p className="text-gray-700">
                          {questionnaire.description}
                        </p>
                      </div>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SquadDetails;
