// TODO: Improve dialog, add checks if a squad is already added, add more data to the results etc.
import { Fragment, useEffect, useState } from "react";
import Results from "../components/Results";
import { Dialog } from "@headlessui/react";
import toast from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  getHealthCheckOverview,
  getOverview,
  getQuestionnaire,
  getQuestionnaireVersion,
} from "../utils/api";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default function ViewQuestionnaire() {
  // use params
  const { id, versionId } = useParams();

  // log id
  console.log(versionId);

  const [squads, setSquads] = useState([]);

  // questionare, version state
  const [questionnaire, setQuestionnaire] = useState({});
  const [version, setVersion] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  // result state
  const [results, setResults] = useState([]);

  // invitations
  const [invitations, setInvitations] = useState([]);

  // squadIds
  const [squadIds, setSquadIds] = useState([]);

  useEffect(() => {
    getQuestionnaireVersion(versionId)
      .then((data) => {
        console.log(data);
        setVersion(data.healthCheck);
        setInvitations(data.invitations || []);
      })
      .catch((error) => {
        console.error(error);
      });

    getQuestionnaire(id)
      .then((data) => {
        console.log(data);
        setQuestionnaire(data);

        getHealthCheckOverview(healthcheckId, versionId).then((data) => {
          console.log(data);
          setResults(data);
        });
      })
      .catch((error) => {
        console.error(error);
      });

    var healthcheckId = id;
  }, []);

  return (
    <div>
      <div className="space-y-4">
        {/* Compare results */}
        <CompareResults results={results} />

        {invitations.length > 0 && (
          <InvitationsCard invitations={invitations} />
        )}
      </div>
    </div>
  );
}

// A card for the invations to the squad members, so the manager can see who has filled in the questionnaire, and send a reminder if needed
export function InvitationsCard(invitations) {
  return (
    <CloseableCard title="Invitations">
      <div className="p-4">
        {invitations &&
          invitations.map((invitation) => {
            return (
              <div
                key={invitation.email}
                className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-5 space-y-1"
              >
                <div className="p-4 flex justify-between items-center">
                  <h3 className="text-2xl font-bold">{invitation.email}</h3>
                  <button className="text-red-500 p-4 bg-red-50 rounded-lg">
                    <TrashIcon className="w-6 h-6" />
                  </button>
                </div>
                <div className="p-4">
                  <p className="text-lg font-semibold">
                    Squad: {invitation.squad}
                  </p>
                  <p className="text-lg font-semibold">
                    Expires at: {invitation.expiresAt}
                  </p>
                  <p className="text-lg font-semibold">
                    {invitation.isUsed ? "Used" : "Not used"}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </CloseableCard>
  );
}

// Component that has children, and a title, and its a closeable card, if you close it you still see the title, but not the children
export function CloseableCard({ title, children }) {
  // State to keep track of if the card is closed or not
  const [isClosed, setIsClosed] = useState(true);

  return (
    <div>
      <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-5 space-y-1 ">
        <div
          className="p-4 cursor-pointer flex justify-between"
          onClick={() => setIsClosed(!isClosed)}
        >
          <h3 className="text-2xl font-bold ">{title}</h3>
          {/* Arrow */}
          <svg
            className={`w-6 h-6 transition-transform duration-1000 transform ${
              isClosed ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/1999/xlink"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
        <div className={` ${isClosed ? "h-0 overflow-hidden" : "h-full"}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

// Components to compare the results of average answer color (red, green, yellow)

// Helper function to calculate the color based on the answer values
const calculateColor = ({ red, yellow, green, disabled }) => {
  if (disabled) return "gray";
  if (green >= red && green >= yellow) return "green";
  if (yellow > red && yellow > green) return "yellow";
  return "red";
};

const CompareResults = ({ results }) => {
  // get color based of box color
  const getColor = (boxColor, isDisabled) => {
    // if the box is disabled, return gray
    if (isDisabled) return "gray";

    switch (boxColor) {
      // 0 = red
      case 0:
        return "red";
      case 6:
        return "green";
      case 3:
        return "yellow";
      default:
        return "gray";
    }
  };

  // list of all the questions, which will be shown in the first row
  const questions =
    results.subList && results.subList[0]
      ? results.subList[0].questionAnswer.map((answer) => {
          return answer.questionId;
        })
      : [];

  // function to save to pdf
  const saveToPdf = () => {
    const print = document.getElementById("print");
    html2canvas(print).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      pdf.text("Results", 10, 10);
      pdf.text("Exported at " + new Date().toLocaleString(), 10, 15);

      // add image to pdf
      pdf.addImage(imgData, "PNG", 10, 20);

      pdf.save("results.pdf");
    });
  };

  // save to json
  const saveToJson = () => {
    const data = JSON.stringify(results);

    const blob = new Blob([data], { type: "application/json" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = "results.json";

    a.click();

    URL.revokeObjectURL(url);

    toast.success("Results saved to json");
  };

  // better save to pdf where the items fit and its placed vertically

  return (
    <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden p-5 space-y-1">
      {/* button to save pdf */}
      <button
        onClick={saveToPdf}
        className="bg-blue-500 text-white p-4 rounded-lg"
      >
        Save to PDF
      </button>

      {/* button to save json */}
      <button
        onClick={saveToJson}
        className="bg-blue-500 text-white p-4 rounded-lg"
      >
        Save to JSON
      </button>

      <div className="w-full text-sm min-w-96" id="print">
        <div className="flex flex-row w-full">
          {/* In the first row it should show the question name */}
          <div class="flex flex-col w-min min-w-44">
            <div className="p-4 flex justify-between items-center">
              <h3 className="text-2xl font-bold opacity-0">x12</h3>
            </div>
            <div className="flex flex-col w-full space-y-2">
              {questions.map((question) => {
                return (
                  <div
                    key={question}
                    className="flex flex-row w-full justify-between items-center h-16  rounded-lg"
                  >
                    <p className="text-lg font-semibold">Question {question}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-row w-full overflow-x-auto">
            {results.subList &&
              results.subList.map((result) => {
                return (
                  <div
                    key={result.squad.id}
                    className="flex flex-col w-full max-w-44"
                  >
                    <div className="p-4 text-center">
                      <img
                        src={result.squad.company.logo}
                        className="w-full h-10 object-contain"
                      />
                      <h3 className="text-sm text-wrap">{result.squad.name}</h3>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      {result.questionAnswer.map((answer) => {
                        return (
                          <div
                            key={answer.questionId}
                            className={`group mx-1 justify-between bg-${getColor(
                              answer.boxColor,
                              answer.disabled
                            )}-500 items-center h-16 overflow-hidden rounded-lg transition-colors duration-150`}
                          >
                            {/* On group hover it should show more info */}
                            <div className="w-full h-full group-hover:opacity-100 opacity-0 transition-opacity duration-150">
                              {/* clip text and make it a color thats the opposite of the box color */}
                              <div className={`flex h-full text-white`}>
                                <p className="text-lg w-full font-semibold bg-red-500 p-4">
                                  {answer.redAnswerCount}
                                </p>
                                <p className="text-lg w-full font-semibold bg-yellow-500 p-4 ">
                                  {answer.yellowAnswerCount}
                                </p>
                                <p className="text-lg w-full font-semibold bg-green-500 p-4 ">
                                  {answer.greenAnswerCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
