import { Fragment, useEffect, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ExclamationTriangleIcon,
  FolderIcon,
  LifebuoyIcon,
  BuildingOffice2Icon,
  UserCircleIcon,
  UsersIcon,
    AcademicCapIcon,
    PaperAirplaneIcon,
    QuestionMarkCircleIcon,

} from "@heroicons/react/24/outline";

import {
  companies,
  users,
  squads,
  questionnaires,
  questions,
  answers,
  invitationLinks,
  squadQuestionnaires,
  squadMembers,
} from "../mock/mockData.js";

const searchTextInFields = (item, fields, query) =>
  fields.some((field) =>
    item[field]?.toLowerCase().includes(query.toLowerCase())
  );

// Entity-specific search implementations
const searchFunctions = {
  companies: (query, filters) =>
    companies
      .filter((company) =>
        searchTextInFields(company, ["company_name", "location"], query)
      )
      .map((company) => ({ ...company, entityType: "company" })),

  users: (query, filters) =>
    users
      .filter(
        (user) =>
          searchTextInFields(user, ["email"], query) &&
          (!filters.role || user.role === filters.role)
      )
      .map((user) => ({ ...user, entityType: "user" })),

  squads: (query, filters) =>
    squads
      .filter((squad) => searchTextInFields(squad, ["squad_name"], query))
      .map((squad) => ({ ...squad, entityType: "squad" })),

  questionnaires: (query, filters) =>
    questionnaires
      .filter(
        (questionnaire) =>
          searchTextInFields(questionnaire, ["title", "description"], query) &&
          (!filters.is_active || questionnaire.is_active === filters.is_active)
      )
      .map((questionnaire) => ({
        ...questionnaire,
        entityType: "questionnaire",
      })),

  // Implement similar functions for questions, answers, invitationLinks, squadQuestionnaires, and squadMembers
  // Implementations for the remaining entities

questions: (query, filters) => questions.filter(question =>
  searchTextInFields(question, ['question_text'], query)
).map(question => ({ ...question, entityType: 'question' })),

answers: (query, filters) => answers.filter(answer =>
  searchTextInFields(answer, ['comment'], query) &&
  (!filters.user_id || answer.user_id === filters.user_id)
).map(answer => ({ ...answer, entityType: 'answer' })),

invitationLinks: (query, filters) => invitationLinks.filter(link =>
  searchTextInFields(link, ['unique_link'], query) &&
  (!filters.is_used || link.is_used === filters.is_used)
).map(link => ({ ...link, entityType: 'invitationLink' })),

squadQuestionnaires: (query, filters) => squadQuestionnaires.filter(sq =>
  questionnaires.some(q => q.id === sq.questionnaire_id && searchTextInFields(q, ['title'], query)) &&
  (!filters.squad_id || sq.squad_id === filters.squad_id)
).map(sq => ({
  ...sq,
  entityType: 'squadQuestionnaire',
  questionnaireTitle: questionnaires.find(q => q.id === sq.questionnaire_id).title
})),

squadMembers: (query, filters) => squadMembers.filter(member =>
  users.some(u => u.id === member.user_id && searchTextInFields(u, ['email'], query)) &&
  (!filters.squad_id || member.squad_id === filters.squad_id)
).map(member => ({
  ...member,
  entityType: 'squadMember',
  userEmail: users.find(u => u.id === member.user_id).email
})),

};

// Unified search function across all entities
function searchAllEntities(query, filters) {
  let resultsLocal = [];
  Object.keys(searchFunctions).forEach((key) => {
    if (!filters.entity || filters.entity === key) {
      resultsLocal.push(...searchFunctions[key](query, filters));
    }
  });

  return resultsLocal;
}

// Search from raw query, .c, .u, .s, .q, .qu, .a, .i, .sq, .sm, and .all
function searchFromRawQuery(query) {
  const [prefix, ...rest] = query.split(" ");
  const restQuery = rest.join(" ");
  switch (prefix) {
    case ".c":
      return searchAllEntities(restQuery, { entity: "companies" });
    case ".u":
      return searchAllEntities(restQuery, { entity: "users" });
    case ".s":
      return searchAllEntities(restQuery, { entity: "squads" });
    case ".q":
      return searchAllEntities(restQuery, { entity: "questionnaires" });
    case ".qu":
      return searchAllEntities(restQuery, { entity: "questions" });
    case ".a":
      return searchAllEntities(restQuery, { entity: "answers" });
    case ".i":
      return searchAllEntities(restQuery, { entity: "invitationLinks" });
    case ".sq":
      return searchAllEntities(restQuery, { entity: "squadQuestionnaires" });
    case ".sm":
      return searchAllEntities(restQuery, { entity: "squadMembers" });
    case ".all":
      return searchAllEntities(restQuery, {});
    default:
      return searchAllEntities(query, {});
  }
}


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchComponent() {
  const [open, setOpen] = useState(false);
  const [rawQuery, setRawQuery] = useState("");
  const query = rawQuery.toLowerCase().replace(/^[#>]/, "");

  //   State of the results
  const [results, setResults] = useState([]);

  //   Add a listener for a ctrl or cmd + k keyboard shortcut to open the search dialog
  const handleKeyDown = (event) => {
    if (event.key === "k" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      setOpen(true);
    }
  };

  const filteredProjects = searchAllEntities(query, { entity: "companies" });

  const filteredUsers = searchAllEntities(query, { entity: "users" });

  const filteredSquads = searchAllEntities(query, { entity: "squads" });

  const filteredQuestionnaires = searchAllEntities(query, {
    entity: "questionnaires",
  });

  const filteredQuestions = searchAllEntities(query, { entity: "questions" });

  const filteredAnswers = searchAllEntities(query, { entity: "answers" });

  const filteredInvitationLinks = searchAllEntities(query, {
    entity: "invitationLinks",
  });

  const filteredSquadQuestionnaires = searchAllEntities(query, {
    entity: "squadQuestionnaires",
  });

  const filteredSquadMembers = searchAllEntities(query, {
    entity: "squadMembers",
  });

  // Add the listener to the window
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

    useEffect(() => {
        setResults(searchFromRawQuery(rawQuery));
        // log the results to the console
        console.log("Results: ", results);
    }, [rawQuery]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setRawQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(item) => (window.location = item.url)}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setRawQuery(event.target.value)}
                  />
                </div>

                {/* filteredSquads, filteredQuestionnaires, filteredQuestions, filteredAnswers, filteredInvitationLinks, filteredSquadQuestionnaires, and filteredSquadMembers */}
                {(results.length > 0 || rawQuery === "?") && (
                  <Combobox.Options
                    static
                    className="max-h-80 transform-gpu scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                  >
                    {results.length > 0 && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                          Results
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                          {results.map((result, index) => (
                            <Combobox.Option
                              key={index}
                              value={result}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center px-4 py-2",
                                  active && "bg-indigo-600 text-white"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  {
                                    // icon for entity type
                                    result.entityType === "company" ? (
                                      <BuildingOffice2Icon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType === "user" ? (
                                      <UserCircleIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType === "squad" ? (
                                      <UsersIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType ===
                                      "questionnaire" ? (
                                      <AcademicCapIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType === "question" ? (
                                      <QuestionMarkCircleIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType === "answer" ? (
                                      <QuestionMarkCircleIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType ===
                                      "invitationLink" ? (
                                      <PaperAirplaneIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType ===
                                      "squadQuestionnaire" ? (
                                      <AcademicCapIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : result.entityType === "squadMember" ? (
                                      <QuestionMarkCircleIcon
                                        className={classNames(
                                          "h-6 w-6 flex-none",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : null
                                  }

                                  <span className="ml-3 flex-auto truncate">
                                    {result.entityType === "company"
                                      ? result.company_name
                                      : result.entityType === "squad"
                                      ? result.squad_name
                                      : result.entityType === "questionnaire"
                                      ? result.title
                                      : result.entityType === "question"
                                      ? result.question_text
                                      : result.entityType === "answer"
                                      ? result.comment
                                      : result.entityType === "invitationLink"
                                      ? result.unique_link
                                      : result.entityType ===
                                        "squadQuestionnaire"
                                      ? result.questionnaireTitle
                                      : result.entityType === "squadMember"
                                      ? result.userEmail
                                      : result.entityType === "user"
                                      ? result.email
                                      : null}
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                  </Combobox.Options>
                )}

                {rawQuery === "?" && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <LifebuoyIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      Help with searching
                    </p>
                    <p className="mt-2 text-gray-500">
                      Type <kbd className="border border-gray-400">?</kbd> to
                      show this help dialog.
                      <br />
                    </p>
                  </div>
                )}

                
                {(rawQuery === ".help" || rawQuery === ".h" || rawQuery === ".?"
                ) && (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                        <LifebuoyIcon
                        className="mx-auto h-6 w-6 text-gray-400"
                        aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                        Help with searching
                        </p>
                        <p className="mt-2 text-gray-500">
                        Type <kbd className="border border-gray-400">.? / .h / .help</kbd> to
                        show this help dialog.
                        <br />
                        
                        <br />
                        Type <kbd className="border border-gray-400">.c</kbd> to
                        search companies.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.u</kbd> to
                        search users.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.s</kbd> to
                        search squads.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.q</kbd> to
                        search questionnaires.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.qu</kbd> to
                        search questions.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.a</kbd> to
                        search answers.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.i</kbd> to
                        search invitation links.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.sq</kbd> to
                        search squad questionnaires.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.sm</kbd> to
                        search squad members.
                        <br />
                        <br />
                        Type <kbd className="border border-gray-400">.all</kbd> to
                        search all entities.
                        </p>
                    </div>

                )}


                {query !== "" &&
                  rawQuery !== ".help" || rawQuery !== ".h" || rawQuery !== ".?"
                   &&
                  filteredAnswers.length === 0 &&
                  filteredInvitationLinks.length === 0 &&
                  filteredQuestionnaires.length === 0 &&
                  filteredQuestions.length === 0 &&
                  filteredSquadMembers.length === 0 &&
                  filteredSquadQuestionnaires.length === 0 &&
                  filteredSquads.length === 0 &&
                  results.length === 0 &&
                  filteredProjects.length === 0 &&
                  filteredUsers.length === 0 && (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <ExclamationTriangleIcon
                        className="mx-auto h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500">
                        We couldn’t find anything with that term. Please try
                        again.
                      </p>
                    </div>
                  )}

                <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
                    
                  Type {" "}
                    <kbd className="border border-gray-400 mx-2">.help</kbd>{" "}    
                    to show a help dialog.

                  {/* Add debug log */}
                  <kbd className="ml-auto">Debug</kbd>
                  {results.length > 0 && (
                    <kbd className="ml-2">{results.length} results</kbd>
                  )}
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}