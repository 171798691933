import { ListBulletIcon, ChatBubbleBottomCenterTextIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCompanies, deleteCompany } from "../utils/api";
import Skeleton from "./Skeleton";
import { Dialog } from "@headlessui/react";

const DeleteConfirmation = ({ isOpen, setIsOpen, confirmDelete, companyId }) => {


  return (
    <Dialog
      className="relative z-50"
      as="div"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="bg-white p-8 rounded-lg shadow-lg">
          <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
            Delete company
          </Dialog.Title>
          <Dialog.Description as="div" className="text-sm text-gray-500">
            Are you sure you want to delete this company? This action cannot be
            undone.
          </Dialog.Description>

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={(e) => confirmDelete(e)}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Yes, delete
            </button>
            <button onClick={() => setIsOpen(false)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
            >Cancel</button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );

};



export default function CompanyComponent() {
  const [companies, setCompanies] = useState(null);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);

  const navigate = useNavigate();

  const confirmDelete = (
    e
  ) => {
    const id = deleteCompanyId;
    e.preventDefault();

    if (e.shiftKey) {
      deleteCompany(id);
    } else {
      setDeleteConfirmationOpen(true);
      deleteCompany(id)
        .then(() => {
          toast.success("Company deleted successfully");
          setDeleteConfirmationOpen(false);
          setDeleteCompanyId(null);
          

          // filter the company from the company state and set the new state without the deleted company
        })
        .catch(() => {
          toast.error("Error deleteing company");
        });
    }

  }


  useEffect(() => {
    getCompanies().then((data) => {
      setCompanies(data.companies.companies);
    });

  }, []);


  return (
    <div>
      <DeleteConfirmation setIsOpen={setDeleteConfirmationOpen} isOpen={deleteConfirmationOpen} confirmDelete={confirmDelete} companyId={deleteCompanyId}/>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {!companies && <Skeleton type={"company"} count={15} />}

        {companies &&
          companies.map((company, index) => (
            <li
              key={index}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
            >
              <div className="flex flex-1 flex-col p-8">
                <Link to={`/company/${company.id}`}>
                <img
                  className="mx-auto h-32 w-32 flex-shrink-0 
                   object-contain
                  rounded-2xl"
                  src={company.logo}
                  alt=""
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {company.name}
                </h3>
                </Link>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Location</dt>
                  <dd className="text-sm text-gray-500">{company.location}</dd>
                </dl>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="-ml-px flex w-0 flex-1">
                    <Link
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      to={`/company/${company.id}`}
                    >
                      <ListBulletIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />

                    </Link>
                    <Link
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      to={`/company/${company.id}/edit`}
                    >

                      <ChatBubbleBottomCenterTextIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />

                    </Link>


                    <button
                      onClick={() => {
                        setDeleteCompanyId(company.id);
                        setDeleteConfirmationOpen(true);
                      }}

                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                      <TrashIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true" />

                    </button>

                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
