import { Link, useLocation } from "react-router-dom";
import logo from "../logo.svg";
import { TvIcon,
  QueueListIcon,
  ReceiptRefundIcon,
  UserGroupIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";


export default function Sidebar() {
  return (
    <div className="bg-secondary-950 w-64 h-screen xl:block hidden ">
      <Link to={"/dashboard"} className="flex items-center justify-center h-32 my-4">
        <img src={logo} alt="logo" className="h-full w-full" />
      </Link>

      <div className="flex flex-col">
        <NavItem
          title="Dashboard"
          icon={<TvIcon className="h-6 w-6" />}
          href="/dashboard"
          active
        />
        <NavItem
          title="Squads"
          icon={<UserGroupIcon className="h-6 w-6" />}
          href="/squads"
        />
        <NavItem
          title="Questionnaires"
          icon={<QueueListIcon className="h-6 w-6" />}
          href="/questionnaires"
        />
        {/* <NavItem
          title="Results"
          icon={<ReceiptRefundIcon className="h-6 w-6" />}
          href="/results"
        /> */}
        <NavItem 
          title="Companies"
          icon={<WrenchIcon className="h-6 w-6" />}
          href="/companies"
        />

      </div>
    </div>
  );
}

function NavItem({ title, icon, href }) {
  var location = useLocation();

  var active = location.pathname === href;

  
  return (
    <Link
      to={href}
      className={`flex items-center px-4 py-2 text-gray-100 ${
        active
          ? "bg-secondary-700 hover:bg-secondary-600"
          : "hover:bg-secondary-900"
      }`}
    >
      {icon}
      <span className="mx-4 font-medium">{title}</span>
    </Link>
  );
    
};



