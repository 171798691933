import React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigation } from "react-router-dom";
import Home from "./views/Home";
import Login from "./views/Auth/Login";
import { Dashboard } from "./layouts/Dashboard";
import Squads from "./views/Squads";
import Questionaires from "./views/Questionnaires";
import ResultsPage from "./views/Results";
import SquadDetails from "./views/SquadDetails";
import Companies from "./views/Companies";
import Company from "./views/Company";
import ViewQuestionnaire from "./views/ViewQuestionnaire";
import EditQuestionnaire from "./views/Forms/Questionnaire/Edit";
import CreateQuestionnaire from "./views/Forms/Questionnaire/Create";
import EditCompany from "./views/Forms/Company/Edit";
import CreateCompany from "./views/Forms/Company/Create";
import CreateSquad from "./views/Forms/Squad/Create";
import QuestionnaireVersions from "./views/QuestionnaireVersions";
import EditSquad from "./views/Forms/Squad/Edit";
// Import other views/pages you have

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Dashboard />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/squads" element={<Squads />} />

          <Route path="/questionnaires" element={<Questionaires />} />

          <Route
            path="/questionnaire/new"
            element={<CreateQuestionnaire />}
          />

          <Route
            path="/questionnaire/:id/edit/:versionId"
            element={<EditQuestionnaire />}
          />

          <Route path="/questionnaire/:id" element={<QuestionnaireVersions />} />

          <Route
            path="/questionnaire/:id/:versionId"
            element={<ViewQuestionnaire />}
          />

          <Route path="/results" element={<ResultsPage />} />
          <Route path="/results/:resultId" element={<ResultsPage />} />

          <Route path="/companies" element={<Companies />} />
          {/* new */}
          <Route path="/company/new" element={<CreateCompany />} />

          <Route path="/company/:companyId" element={<Company />} />
          <Route path="/company/:companyId/edit" element={<EditCompany />} />

          {/* New squad */}
          <Route path="/squad/new" element={<CreateSquad />} />
          <Route path="/squad/:squadId/edit" element={<EditSquad />} />

          

          <Route path="/squad/:squadId" element={<SquadDetails />} />
          {/* Add other routes here */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
