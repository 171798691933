// src/layouts/Dashboard.js
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SearchComponent from "../components/Search";
import { Toaster } from "react-hot-toast";

export function Dashboard() {
  // Name of current page
  const location = useLocation();
  const pageName = location.pathname.split("/")[1];

  // Build breadcrumb
  const breadcrumb = location.pathname.split("/").slice(1);
  
  return (
    <div className="flex max-h-screen overflow-hidden bg-gray-100 relative">
      <div className="flex flex-shrink-0">
        <Sidebar />
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <SearchComponent />
        <main className="flex-1 overflow-hidden bg-gray-200 h-full overflow-y-auto relative">
          <Navbar pageName={pageName} breadcrumb={breadcrumb} />

          <div className="container mx-auto px-6 py-8 ">
            <Outlet />
          </div>
        </main>
      </div>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 2000,
          style: {
            padding: "16px",
          },
        }}
        reverseOrder={false}
      />
    </div>
  );
}

