import React, { useEffect, useState } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { ChatBubbleBottomCenterIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import { getQuestionnaires } from "../utils/api";
import Skeleton from "./Skeleton";

function QuestionnairesComponent() {
  const [questionnaires, setQuestionnaires] = useState();

  useEffect(() => {
    getQuestionnaires().then((questionnaires) => {
      setQuestionnaires(questionnaires.healthChecks.healthChecks);
    });
  }
  , []);

  return (
    // not all the same size
    <div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {!questionnaires && <Skeleton type={"questionnaire"} count={15} />}
        {questionnaires &&
          questionnaires.map((q) => (
            <Link to={`/questionnaire/${q.id}`} key={q.id}>
              <QuestionnaireCard q={q} />
            </Link>
          ))}
      </div>
    </div>
  );
}

// 

export function QuestionnaireCard({ q }) {
  return (
    <div
      key={q.id}
      className="bg-white shadow overflow-hidden sm:rounded-lg h-full w-full flex flex-col justify-between"
    >
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {q.title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 truncate">
            {q.description}
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {q.isActive ? "Active" : "Inactive"}
              </dd>
            </div>
            {/* Created At */}
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {q.createdAt}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px flex w-0 flex-1">
            <Link
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              to={`/results/${q.id}`}
            >
              <ListBulletIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              View results
            </Link>
            <Link
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              to={`/questionnaire/${q.id}/edit/${q.version}`}
            >
              <ChatBubbleBottomCenterIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionnairesComponent;
