// Navbar


// Path: Client/src/components/Sidebar.js
import { Link } from "react-router-dom";
import logo from "../logo.svg";

function Navbar({pageName, breadcrumb}) {
  // page name
  // const pageName = "Dashboard";
    return (
      <div>
        <div className="bg-secondary-950 p-6 shadow-md sticky top-0">
            
            <Link to={"/"} className="block xl:hidden">
                <img src={logo} alt="logo" className="w-20 h-20" />
            </Link>
        </div>
        <div className="bg-primary-500 p-6 shadow-md">
          <h1 className="text-3xl text-secondary-950 font-semibold first-letter:uppercase">
            {pageName}
          </h1>
          {/* Breadcrumb */}
          {
            breadcrumb.length > 1 ? (
              <nav className="mt-4" aria-label="Breadcrumb">
                <ol className="flex items-center">
                  {breadcrumb.map((item, index) => (
                    <li key={index}>
                      <div className="flex items-center">
                        <a href="/" className="text-sm font-medium text-secondary-900 hover:text-secondary-700 first-letter:uppercase">
                          {item}
                        </a>
                        {index < breadcrumb.length - 1 ? (
                          <span className="mx-2.5 text-sm font-medium text-secondary-700">/</span>
                        ) : null}
                      </div>
                    </li>
                  ))}
                </ol>
              </nav>
            ) : null
          }
        </div>
      </div>
    );
}

export default Navbar;