import { Link } from "react-router-dom";
import QuestionnaireComponent from "../components/Questionnaires";


function Questionaires() {
  return (
    <div>
      {/* Button to create new vragenlijst */}
      <div className="flex justify-left my-5">
        <Link to="/questionnaire/new" className="text-center">
          <div className="bg-primary-700 hover:bg-primary-900 text-white font-bold py-2 px-4 rounded">
            Create new questionnaire
          </div>
        </Link>
      </div>
      <QuestionnaireComponent />
    </div>
  );
}

export default Questionaires;